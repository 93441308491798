











































import { Component, Vue } from "vue-property-decorator";
import PageBox from "@/components/PageBox/PageBox.vue";
import OrderInfo from "@/views/applet/orderManagement/components/orderInfo/OrderInfo.vue";
import ShopInfo from "@/views/applet/orderManagement/components/shopInfo/ShopInfo.vue";
import SaleInfo from "@/views/applet/orderManagement/components/saleInfo/SaleInfo.vue";
import { Commodity } from "@/api/AppletWeb";
@Component({
    components: {
        PageBox,
        OrderInfo,
        ShopInfo,
        SaleInfo,
    },
})
export default class viewOrderInfo extends Vue {
    private isorder = 1;
    private infoData = {};
    private productList = [];
    private SaleList = [];
    private userObj = {
        user: {},
        farmName: {},
    };
    private voucherObj = {
      orderVoucherObj:{},
      remarkObj:{},
      payVoucherObj:{}
    }
    private switchTab(type: number) {
        this.isorder = type;
    }
    private orderId = "";
    activated() {
        this.isorder = 1;
        const query = this.$route.query;
        console.log(query);
        this.orderId = query.orderId;
        console.log(this.orderId, "orderId")
        this.getOrderInfo(query);
        this.getSaleList(query);
    }
    private getOrderInfo(query: any) {
        new Commodity().orderInfo(query.orderId, (res: any) => {
            let data = res.data;
            if (data.huimainiuOrderDeliveryRespDtoList) {
                data.huimainiuOrderDeliveryRespDtoList.forEach((item: any) => {
                    if (item.truckLoadingImg) {
                        if (item.truckLoadingImg.includes(",")) {
                            item.truckLoadingImg = item.truckLoadingImg.split(
                                ","
                            );
                        } else {
                            item.truckLoadingImg = [item.truckLoadingImg];
                        }
                    } else {
                        item.truckLoadingImg = [];
                    }
                });
            }
            this.infoData = data;
            const orderVoucherObj = {
                gbvideoList: [], //过磅
                gbimgList: [], //过磅
                czvideoList: [], //称重
                czimgList: [], //称重
                scvideoList: [], //上车
                scimgList: [], //上车
                dcgbvideoList: [], //到场过磅
                dcgbimgList: [], //到场过磅
                xcvideoList: [], //下车
                xcimgList: [], //下车
                gbTime:'',
                czTime:'',
                scTime:'',
                dcgbTime:'',
                xcTime:'',
            };
            if (data.orderVoucherList) {
                data.orderVoucherList.forEach((item:any) => {
                    const { filePath, videoFirstFrame, type } = item;
                    // item.filePath = filePath.split(",");
                    // item.videoFirstFrame = videoFirstFrame.split(",");

                    switch (type) {
                        case 1:
                            orderVoucherObj.gbTime = item.createTime;
                            if (item.fileType == 1) {
                                orderVoucherObj.gbimgList.push(item.filePath);
                            }else{
                                orderVoucherObj.gbvideoList.push(item.filePath);
                            }
                            console.log(orderVoucherObj)
                            break;
                        case 2:
                            orderVoucherObj.czTime = item.createTime;
                            if (item.fileType == 1) {
                                orderVoucherObj.czimgList.push(item.filePath);
                            }else{
                                orderVoucherObj.czvideoList.push(item.filePath);
                            }
                            break;
                        case 3:
                            orderVoucherObj.scTime = item.createTime;
                            if (item.fileType == 1) {
                                orderVoucherObj.scimgList.push(item.filePath);
                            }else{
                                orderVoucherObj.scvideoList.push(item.filePath);
                            }
                            break;
                        case 4:
                            orderVoucherObj.dcgbTime = item.createTime;
                            if (item.fileType == 1) {
                                orderVoucherObj.dcgbimgList.push(item.filePath);
                            }else{
                                orderVoucherObj.dcgbvideoList.push(item.filePath);
                            }
                            break;
                        case 5:
                            orderVoucherObj.xcTime = item.createTime;
                            if (item.fileType == 1) {
                                orderVoucherObj.xcimgList.push(item.filePath);
                            }else{
                                orderVoucherObj.xcvideoList.push(item.filePath);
                            }
                            break;
                        default:
                            break;
                    }
                });
            }
            this.voucherObj.orderVoucherObj = orderVoucherObj;
            const remarkObj = {
                djList: [], //定金备注
                wkList: [], //尾款
                qkList: [], //全款
            };
            if (data.remarkList) {
                data.remarkList.forEach((item:any) => {
                    const { type } = item;
                    switch (type) {
                        case 1:
                            remarkObj.djList.push(item);
                            break;
                        case 2:
                            remarkObj.wkList.push(item);
                            break;
                        case 3:
                            remarkObj.qkList.push(item);
                            break;
                        default:
                            break;
                    }
                });
            }
            this.voucherObj.remarkObj = remarkObj;
            const payVoucherObj = {
                djList: [], //定金凭证
                wkList: [], //尾款
                qkList: [], //全款
            };
            if (data.payVoucherList) {
                data.payVoucherList.forEach((item:any) => {
                    const { type } = item;
                    switch (type) {
                        case 1:
                            payVoucherObj.djList.push(item.voucher);
                            break;
                        case 2:
                            payVoucherObj.wkList.push(item.voucher);
                            break;
                        case 3:
                            payVoucherObj.qkList.push(item.voucher);
                            break;
                        default:
                            break;
                    }
                });
            }
            this.voucherObj.payVoucherObj = payVoucherObj;
            this.productList = data.huimainiuOrderProductRespDtoList;
            this.userObj.user = data.user;
            this.userObj.farmName = data.farmName;
        });
    }
    private getSaleList(query: any) {
        new Commodity().afterSaleList(query.orderId, (res: any) => {
            let data = res.data;
            data.forEach((item: any) => {
                if (item.refundImgs) {
                    if (item.refundImgs.includes(",")) {
                        item.refundImgs = item.refundImgs.split(",");
                    } else {
                        item.refundImgs = [item.refundImgs];
                    }
                } else {
                    item.refundImgs = [];
                }
            });
            this.SaleList = data;
        });
    }
}
