import { BasicsClass } from '@/common/BasicsClass';
import { userForm, CommodityForm, bannerForm } from '@/Interface/AppletWeb'


export class User {
    public list(form: userForm, page: any, cb: Function) {
        new BasicsClass().BasicGet1('/animal/web/huimainiuUser/list', { ...form, ...page }, false, false, cb)
    }
    public updateStatus(id: string, status: number, cb: Function) {
        new BasicsClass().BasicPost('/animal/web/huimainiuUser/updateStatus', { id, status }, true, true, true, cb)
    }
}

export class Commodity {
    public list(form: CommodityForm, page: any, cb: Function) {
        new BasicsClass().BasicGet1('/animal/web/huimainiuProduct/list', { ...form, ...page }, false, false, cb)
    }
    public getAllCowTypes(cb: Function) {
        new BasicsClass().BasicGet1('/animal/web/confTypeInfo/getAllCowTypes', {}, false, false, cb)
    }
    public getAllCowBreeds(cb: Function) {
        new BasicsClass().BasicGet1('/animal/web/confTypeInfo/getAllCowBreeds', {}, false, false, cb)
    }
    public info(id: string, cb: Function) {
        new BasicsClass().BasicGet1('/animal/web/huimainiuProduct/info', { id }, false, false, cb)
    }
    public check(form: any, cb: Function) {
        new BasicsClass().BasicPost1("/animal/web/huimainiuProduct/check", { ...form }, true, true, true, cb)
    }
    public bannerList(form: bannerForm, page: any, cb: Function) {
        new BasicsClass().BasicGet1('/animal/web/huimainiuBanner/list', { ...form, ...page }, false, false, cb)
    }
    public bannerDown(form: any, cb: Function) {
        new BasicsClass().BasicPost1("/animal/web/huimainiuBanner/updateStatus", { ...form }, false, true, true, cb)
    }
    public bannerEdit(form: any, cb: Function) {
        new BasicsClass().BasicPost1("/animal/web/huimainiuBanner/updateOne", { ...form }, false, true, true, cb)
    }
    public productList(form: bannerForm, page: any, cb: Function) {
        new BasicsClass().BasicGet1('/animal/web/huimainiuBanner/productList', { ...form, ...page }, false, false, cb)
    }
    public productListtwo(form: any, page: any, cb: Function) {
        new BasicsClass().BasicGet('/animal/web/ordering/goods/pageByMerchantId', { ...form, ...page }, false, false, cb)
    }
    public bannerAdd(form: any, cb: Function) {
        new BasicsClass().BasicPost1("/animal/web/huimainiuBanner/add", { ...form }, false, true, true, cb)
    }
    public bannerInfo(id: string, cb: Function) {
        new BasicsClass().BasicGet1('/animal/web/huimainiuBanner/detail', { id }, false, false, cb)
    }
    public orderList(form: any, page: any, cb: Function) {
        new BasicsClass().BasicGet1('/animal/web/huimainiuOrder/list', { ...form, ...page }, false, false, cb)
    }
    public orderInfo(orderId: string, cb: Function) {
        new BasicsClass().BasicGet1('/animal/web/huimainiuOrder/detail', { orderId }, false, false, cb)
    }
    public afterSaleList(orderId: string, cb: Function) {
        new BasicsClass().BasicGet1('/animal/web/huimainiuOrder/afterSaleList', { orderId }, false, false, cb)
    }
    public getOrderLocus(form: any, cb: Function) {
        new BasicsClass().BasicGet1('/animal/web/huimainiuOrder/getOrderLocus', { ...form }, false, false, cb)
    }
}