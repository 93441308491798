















































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class ShopInfo extends Vue {
    @Prop(Array) private productList!: any;
    private tablePage = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };
}
