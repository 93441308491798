















































































import { Component, Vue, Prop } from "vue-property-decorator";
import PageBox from "@/components/PageBox/PageBox.vue";
@Component({
  components: {
    PageBox,
  },
})
export default class SaleInfo extends Vue {
  @Prop(Array) private SaleList!: any;
  @Prop(Object) private userObj!: any;
  private maskShow = false;
  private maskImg = "";
  private srcList = [];
  private handleMask(): void {
    this.maskShow = false;
  }
  private handleImg(data: any): void {
    this.maskShow = true;
    this.maskImg = data;
  }
  private vbs(data: any): void {
    this.srcList = data;
  }
}
